<template>
    <el-dialog
        width="500px"
        top="40px"
        :visible.sync="visible"
        :before-close="closeModal"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @open="modalOpen"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('services.edit_service') }}
                </p>
            </div>
        </span>

        <div class="mb-14 mt-8 px-10">
            <el-form
                ref="form"
                v-loading="$wait.is('loading.*')"
                :model="formData"
                label-position="top"
                :rules="rules"
                element-loading-spinner="el-custom-spinner"
            >
                <el-form-item :label="$t('services.category')">
                    <el-select v-model="formData.category_id" class="w-full" :placeholder="$t('services.category')">
                        <el-option
                            :label="$t('services.none')"
                            :value="null"
                        />
                        <el-option
                            v-for="category in categoriesData"
                            :key="category.id"
                            :label="category.name"
                            :value="category.id"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('services.name')" prop="name">
                    <div class="flex">
                        <el-input v-model="formData.name" />
                        <el-color-picker v-model="formData.color" class="ml-2" :predefine="predefinedColors" />
                    </div>
                </el-form-item>

                <el-form-item v-if="hasFortnox" :label="$t('services.fortnox_article_id')" prop="fortnox_article_id">
                    <div class="flex">
                        <el-input v-model="formData.fortnox_article_id" />
                    </div>
                </el-form-item>

                <el-form-item :label="$t('services.price')" prop="price">
                    <el-input v-model.number="formData.price" type="number" @change="formatDecimals" />
                </el-form-item>

                <div v-if="formData.price_type === 'timbank'" class="grid grid-cols-2">
                    <el-form-item :label="$t('services.timbank_volume_hours')" prop="timbank_volume_hours">
                        <el-input-number v-model="timbank_volume_hours" :precision="0" :min="1" :step="1" :controls-position="'right'" />
                    </el-form-item>
                    <el-form-item :label="$t('services.timbank_volume_minutes')" prop="timbank_volume_minutes">
                        <el-input-number v-model="timbank_volume_minutes" :precision="0" :min="0" :max="45" :step="15" :controls-position="'right'" />
                    </el-form-item>
                </div>

                <el-form-item :label="$t('services.price_type')">
                    <el-radio-group v-model="formData.price_type" :disabled="true">
                        <el-radio-button label="hourly">
                            {{ $t('services.hourly') }}
                        </el-radio-button>
                        <el-radio-button label="once">
                            {{ $t('services.once') }}
                        </el-radio-button>
                        <!-- <el-radio-button label="timbank">
                            Timbank
                        </el-radio-button> -->
                    </el-radio-group>
                </el-form-item>

                <div class="grid grid-cols-2">
                    <el-form-item label="RUT">
                        <el-switch
                            v-model="formData.rut"
                            active-color="#13ce66"
                            inactive-color="#20a0ff"
                            :active-value="1"
                            :inactive-value="0"
                            :active-text="$t('common.yes')"
                            :inactive-text="$t('common.no')"
                            :disabled="formData.rot === 1"
                        />
                    </el-form-item>

                    <el-form-item label="ROT">
                        <el-switch
                            v-model="formData.rot"
                            active-color="#13ce66"
                            inactive-color="#20a0ff"
                            :active-value="1"
                            :inactive-value="0"
                            :active-text="$t('common.yes')"
                            :inactive-text="$t('common.no')"
                            :disabled="formData.rut === 1"
                        />
                    </el-form-item>
                </div>
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('updating')" :disabled="$wait.is('loading.*')" @click="updateService">{{ $t('common.update') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>
<script>
import Api from '../services.api';
import ApiCategories from '../../services_categories/categories.api';

export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        serviceId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            categoriesData:         [],
            timbank_volume_hours:   1,
            timbank_volume_minutes: 0,
            formData:               {
                category_id: null,
            },
            predefinedColors: [
                '#c92918', '#00d1be', '#0097d1', '#3a983a', '#0071d1', '#8433de', '#db33de', '#de3372', '#33deaf', '#9a8974',
            ],
            rules: {
                name:  [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                price: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    computed: {
        hasFortnox() {
            return this.$store.state.user.user.has_fortnox;
        },
    },

    methods: {
        modalOpen() {
            this.getCategories();
            this.getDetails();
        },

        async getDetails() {
            try {
                this.$wait.start('loading.details');
                const data = await Api.getDetails(this.serviceId);
                data.category_id = data?.category?.id || null;
                this.formData = data;
                this.formData.price = parseFloat(this.formData.price / 100).toFixed(2);
                this.timbank_volume_hours = Math.floor(this.formData.timbank_volume / 60);
                this.timbank_volume_minutes = this.formData.timbank_volume - (Math.floor(this.formData.timbank_volume / 60) * 60);
            } finally {
                this.$wait.end('loading.details');
            }
        },

        async getCategories() {
            try {
                this.$wait.start('loading.categories');
                this.categoriesData = await ApiCategories.getAll();
            } finally {
                this.$wait.end('loading.categories');
            }
        },

        async updateService() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$alert(this.$t('updating_your_service_data_be_aware_that_changes_will_be_reflected_only_for_future_events_in_calendar'), this.$t('services.information'), {
                confirmButtonText: 'OK',
                showClose:         false,
                callback:          () => this.update(),
            });
        },

        async update() {
            try {
                this.$wait.start('updating');
                await Api.update(this.serviceId, this.formData, this.timbank_volume_hours, this.timbank_volume_minutes);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
            } finally {
                this.$wait.end('updating');
                this.closeModal();
            }
        },

        formatDecimals(value) {
            if (!value) return;
            this.formData.price = parseFloat(value).toFixed(2);
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
